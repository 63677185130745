import React, {memo, useEffect, useState} from "react";
import {Country} from "../../types";
import ClientAPI from "../../api/fuelcheckAPI";
import {useAuth} from "../../hooks/authContext";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, InputLabel} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "../../hooks/useTranslation";

interface Props {
    onCountryChange: (country: string) => void
}

const CountrySelect= memo(function CountrySelect({onCountryChange}: Props) {
    const {user} = useAuth();
    const [countries, setCountries] = useState<Country[]>([]);
    const [pickedCountry, setPickedCountry] = useState<string>( '');
    const translate = useTranslation()
    const [searchParams] = useSearchParams();
    const country = searchParams.get('country')
    function handleChangeCountry(event: SelectChangeEvent) {
        const value = event.target.value;
        setPickedCountry(value);
        //pass pickedCountry to parent component
        onCountryChange(value)
    }

    useEffect(() => {
        //comparator for sorting by country.name
        function compareByCountryName(a: any, b: any) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0;
        }

        ClientAPI.getCountriesList().then((resp: Country[]) => {
            setCountries(resp.sort(compareByCountryName))
            if (country){
                const countryFromParams = resp.find(c => c.shortcut === country);
                if (countryFromParams) {
                    setPickedCountry(countryFromParams.shortcut);
                    //pass pickedCountry to parent component
                    onCountryChange(countryFromParams.shortcut)
                }
            }else{
                const defaultUserCountry = resp.find(c => c.shortcut === user?.country);
                if (defaultUserCountry) {
                    setPickedCountry(defaultUserCountry.shortcut);
                    //pass pickedCountry to parent component
                    onCountryChange(defaultUserCountry.shortcut)
                }
            }

        });
    }, [user?.country,onCountryChange]);


    return (
        <FormControl fullWidth size="small">
            <InputLabel id={"Country"}>{translate('setting.user.table.country')}</InputLabel>
            <Select
                value={pickedCountry}
                label={translate('setting.user.table.country')}
                labelId="Country"
                onChange={handleChangeCountry}
            >
                {countries?.map((country, i) =>
                    <MenuItem value={country.shortcut} key={i}>{country.name}</MenuItem>
                )}
                {countries.length === 0 && <MenuItem disabled>Loading...</MenuItem>}
            </Select>
        </FormControl>
    );
})

export default CountrySelect;