import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {Route, Routes} from "react-router-dom";
import {useAuth} from "./hooks/authContext";
import {RestrictedRoute} from "./components/RestrictedRoute";
import {PrivateRoute} from "./components/PrivateRoute";
import {theme} from "./theme";
import {lazy} from "react";
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import LoginPage from "./pages/LoginPage/LoginPage";
import DontExistPage from "./pages/Error/404Page";
import DHLUploaderPage from "./pages/DHLUploader/DHLUploaderPage";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import TranVPage from "./pages/TranV/TranVPage";
import {ApplicationZones} from "./types";
import {ProtectedRoute} from "./components/ProtectedRoute";
import Languages from "./pages/Languages/LanguagePage";

const DashboardPage = lazy(() => import("./pages/DashboardPage/DashboardPage"));
const ReportPage = lazy(() => import("./pages/ReportPage/ReportPage"));
const DietReport = lazy(() => import("./pages/DietReport/DietReport"));
const DriverActivityReport = lazy(() => import("./pages/DriverActivityReport/DriverActivityReport"));
const Logbook = lazy(() => import("./pages/Logbook/Logbook"));
const VehiclePositions = lazy(() => import("./pages/VehiclePositions/VehiclePositions"));
const AdminReportPage = lazy(() => import("./pages/AdminReport/AdminReportPage"));

const DenmarkUploader = lazy(() => import("./pages/DenmarkUploader/DenmarkUploaderPage"));
const VehicleInfo = lazy(() => import("./pages/DenmarkUploader/VehicleInfo"))
const ProfilesPage = lazy(() => import("./pages/Profiles/ProfilesPage"));
const DietsPage = lazy(() => import("./pages/Diets/DietsPage"));
const Depots = lazy(() => import("./pages/Depots/DepotsPage"));
const VehiclesAndDriversPage = lazy(() => import("./pages/VehiclesAndDrivers/VehiclesAndDriversPage"));
const UsersPage = lazy(() => import("./pages/Users/UsersPage"));

const MailPage = lazy(() => import("./pages/Mail/MailPage"));
const ConversionTablePage = lazy(() => import("./pages/ConversionTable/ConversionTablePage"));
const InspectVehiclePage = lazy(() => import("./pages/InspectionPages/InspectVehiclePage"));


export default function App() {
    const {isFetching} = useAuth()
    const queryClient = new QueryClient()

    // const location = useLocation();
    // const navigate = useNavigate();
    // const history = useHistory();

    // TODO: fix bug with restricted route. Do not redirect from login when logged in
    // useEffect(() => {
    //     if (location.pathname !== '/login') {
    //         localStorage.setItem("location", JSON.stringify(location.pathname));
    //     } else {
    //         localStorage.removeItem("location");
    //     }
    // }, [location.pathname]);
    //
    // useEffect(() => {
    //     const storedLocation = localStorage.getItem("location");
    //     if (storedLocation) {
    //         navigate(JSON.parse(storedLocation));
    //     }
    // }, [navigate]);


    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {!isFetching &&
                    <Routes>
                        <Route path="/" element={<PrivateRoute component={<NavigationMenu/>}/>}>
                            {/*GENERAL*/}
                            <Route index element={<DashboardPage/>}/>
                            <Route path="/report"
                                   element={<ProtectedRoute component={<ReportPage/>}
                                                            pageName={"Report"}
                                                            neededAccess={ApplicationZones.DT}/>}/>
                            <Route path="/diet_report"
                                   element={<ProtectedRoute component={<DietReport/>}
                                                            pageName={"Diet Report"}
                                                            neededAccess={ApplicationZones.DA}/>}
                            />
                            <Route path='/driver_activity_report'
                                   element={<ProtectedRoute component={<DriverActivityReport/>}
                                                            neededAccess={ApplicationZones.DA}
                                                            pageName={"Driver Activity Report"}/>}
                            />
                            <Route path="/logbook"
                                   element={<ProtectedRoute component={<Logbook/>}
                                                            pageName={'Logbook'}
                                                            neededAccess={ApplicationZones.LB}/>}
                            />
                            <Route path="/vehicle/positions"
                                   element={<ProtectedRoute component={<VehiclePositions/>}
                                                            neededAccess={ApplicationZones.VP}
                                                            pageName={"Vehicle Positions"}/>}/>
                            <Route path="/admin_report"
                                   element={<ProtectedRoute component={<AdminReportPage/>}
                                                            pageName={'Admin Report'}
                                                            neededAccess={ApplicationZones.ADMIN}/>
                                   }/>
                            {/*INTEGRATIONS*/}
                            <Route path="/denmark"
                                   element={<ProtectedRoute component={<DenmarkUploader/>}
                                                            neededAccess={ApplicationZones.DK}
                                                            pageName={"Denmark Uploader"}/>}
                            />
                            <Route path="/denmark/:truckId"
                                   element={<ProtectedRoute component={<VehicleInfo/>}
                                                            neededAccess={ApplicationZones.DK}
                                                            pageName={"Denmark Vehicle Info"}/>}/>

                            <Route path="/dhl"
                                   element={<ProtectedRoute component={<DHLUploaderPage/>}
                                                            neededAccess={ApplicationZones.DH}
                                                            pageName={"DHL Uploader"}/>}
                            />
                            {/*SETTINGS*/}
                            <Route path="/profiles"
                                   element={<ProtectedRoute component={<ProfilesPage/>}
                                                            pageName={"Profiles"}
                                                            neededAccess={ApplicationZones.DT}/>}/>
                            <Route path="/diets"
                                   element={<ProtectedRoute component={<DietsPage/>}
                                                            neededAccess={ApplicationZones.DA}
                                                            pageName={"Driver Activity Report"}/>}
                            />
                            <Route path="/depots" element={<Depots/>}/>
                            <Route path="vehicles" element={<VehiclesAndDriversPage/>}/>
                            <Route path="vehicles/:username/:vin" element={<InspectVehiclePage/>}/>
                            {/*ADMINISTRATION*/}
                            {/*<Route path="/admin" element={<AdminPage/>}/>*/}
                            {/*<Route path="/customers" element={<CustomersPage/>}/>*/}
                            <Route path="/languages"
                                   element={<ProtectedRoute component={<Languages/>}
                                                            neededAccess={ApplicationZones.ADMIN}
                                                            pageName={"Languages"}/>}/>
                            <Route path="/users"
                                   element={<ProtectedRoute component={<UsersPage/>}
                                                            neededAccess={ApplicationZones.ADMIN}
                                                            pageName={"Users"}/>}/>
                            <Route path="/mail"
                                   element={<ProtectedRoute component={<MailPage/>}
                                                            neededAccess={ApplicationZones.ADMIN}
                                                            pageName={"Driver Activity Report"}/>}/>
                            <Route path="/conversions"
                                   element={<ProtectedRoute component={<ConversionTablePage/>}
                                                            neededAccess={ApplicationZones.DT}
                                                            pageName={"Driver Activity Report"}/>}/>
                            <Route path="/tranv"
                                   element={<ProtectedRoute component={<TranVPage/>}
                                                            neededAccess={ApplicationZones.ADMIN}
                                                            pageName={"Driver Activity Report"}/>}/>
                        </Route>

                        <Route
                            path="/login"
                            element={
                                <RestrictedRoute redirectTo="/" component={<LoginPage/>}/>
                            }
                        />

                        <Route path='*' element={<DontExistPage/>}/>
                    </Routes>
                }
            </ThemeProvider>
        </QueryClientProvider>
    );
}


