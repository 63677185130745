import styled from "@emotion/styled";
import Container from '@mui/material/Container';
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: #a2adc0;
`

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`
export const StyledForm = styled.form`
  width: 500px;
  height: 400px;
  background-color: #f7f8f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;
  @media only screen and (max-width: 768px) {
      width: 90vw;
  }
  @media only screen and (min-width: 2000px) {
    width: 30vw;
  }
`
export const LoginPageContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
min-height: 100vh;
background-color: #0f113d;
`
export const StyledContainer = styled(Container)`
  width: 500px;
  height: 400px;
  background-color: #f7f8f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 2rem;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
  @media only screen and (min-width: 2000px) {
    width: 30vw;
  }
`