import React from "react";
import ZauzooFullLogo from '../../zauzoo_logo_full.svg'

export default function FooterBar() {

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center",justifyContent:"flex-end", color: "#a2adc0"}}>
            <figure style={{margin: "0 auto", width: 200, height: 50}}>
                <img src={ZauzooFullLogo} alt='Zauzoo logo' width="100%"/>
            </figure>
            <figure style={{margin: "0 auto", width: 200, height: 50}}>
                <img src={ZauzooFullLogo} alt='Zauzoo logo' width="100%"/>
            </figure>
            <div style={{maxWidth: "90%", textAlign: "center"}}>
                <p style={{fontSize: "0.8rem"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate
                    eaque repudiandae voluptas, aliquid culpa molestias beatae at fugiat voluptatem obcaecati dolores,
                    officia amet nihil placeat sequi ullam eveniet, architecto quisquam. Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Tempora expedita qui suscipit ullam adipisci, minus excepturi minima
                    quasi fuga nesciunt, maiores aliquam, reprehenderit architecto voluptates perferendis nulla ipsum
                    laboriosam soluta!</p>
                <p style={{fontSize: "0.8rem"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam tempore
                    tenetur porro voluptate et ipsa culpa a id perspiciatis? Explicabo accusamus repellendus quae, fuga
                    harum sed dolorum magnam molestiae voluptate.</p>
            </div>

        </div>
    )
}