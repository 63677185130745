import React, {useEffect, useState} from 'react'
import {TrailerDhl} from "../../types";
import ClientAPI from "../../api/fuelcheckAPI";
import Typography from "@mui/material/Typography";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import {ContentPaperWrapper} from "../../AppStyles";
import Loader from "../../components/Loading/Loader";


type TrailersProps = {
    username: string
}

function Trailers({username}: TrailersProps) {
    const [trailersList, setTrailersList] = useState<TrailerDhl[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        if (username) {
            setIsFetching(true);
            ClientAPI.dhl.getTrailers(username)
                .then(resp => setTrailersList(resp))
                .finally(() => setIsFetching(false));
        } else {
            setTrailersList([])
        }
    }, [username]);
    return (
        <ContentPaperWrapper basis={"40%"}>

            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6" component="h6">
                        Company Trailers
                    </Typography>
                </Grid>
                <Grid item>
                    <Button size={"small"}
                            variant="contained"
                            color={"secondary"}
                            disabled={!username}
                        // onClick={handleClickAdd}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>

            {isFetching ?
                <Loader/>
                :
                <TableContainer sx={{marginTop: "1rem", overflowY: "auto"}}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Registration number</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trailersList.map(trailer =>
                                <TableRow
                                    key={trailer.trailerId}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>{trailer.regno}</TableCell>
                                    <TableCell align={"right"}>
                                        <Button size={"small"}
                                                variant="outlined"
                                                color={"secondary"}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </ContentPaperWrapper>
    )
}

export default Trailers
