import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {Dispatch, SetStateAction, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type Props = {
    open: boolean,
    user: string
    closeDialog: Dispatch<SetStateAction<boolean>>
}

type PasswordFields = {
    pass: string,
    passAgain: string
}

const initialState = {
    pass: "",
    passAgain: ""
}


export function ChangePasswordDialog({open, user, closeDialog}: Props) {
    const [passwordFields, setPasswordFields] = useState<PasswordFields>(initialState);
    const [showError, setShowError] = useState(false);
    function handleCloseDialog() {
        closeDialog(false)
        setPasswordFields(initialState)
        setShowError(false)
    }

    function handleChangeInputs(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        const key = event.target.id
        const validKey = Object.keys(passwordFields).includes(key)
        if (!validKey) {
            return
        }

        if (value===""){
            setShowError(false)
        }
        setPasswordFields({...passwordFields, [key]: value})
    }

    function handleSaveNewPassword() {
        if (passwordFields.pass === passwordFields.passAgain) {
            //     send request to save new pass
            // if response status 200
            handleCloseDialog()
        } else {
            setShowError(true)
        }
    }

    return (
        <Dialog open={open} onClose={handleCloseDialog} maxWidth={'lg'}>
            <DialogTitle>Change Password</DialogTitle>

            <DialogContent>
                <Typography variant={'h6'}> Username: {user}</Typography>
                <Box sx={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                    <TextField size={"small"}
                               error={showError}
                               helperText={showError?"Passwords are not equal":""}
                               id="pass"
                               type={"password"}
                               label="Password"
                               variant="outlined"
                               value={passwordFields.pass}
                               onChange={handleChangeInputs}/>
                    <TextField size={"small"}
                               error={showError}
                               helperText={showError?"Passwords are not equal":""}
                               id="passAgain"
                               type={"password"}
                               label="Password Again"
                               variant="outlined"
                               value={passwordFields.passAgain}
                               onChange={handleChangeInputs}/>
                </Box>

            </DialogContent>

            <DialogActions>
                <Button variant="contained" type={"submit"} sx={{bgcolor: "secondary.main"}}
                        onClick={handleSaveNewPassword}
                >
                    Save
                </Button>
                <Button variant="contained" type={"submit"} sx={{bgcolor: "secondary.main"}}
                        onClick={handleCloseDialog}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}