import LoginHeader from "./LoginHeader";
import LoginForm from "./LoginForm";
import FooterBar from "../../components/FooterBar/FooterBar";
import {LoginPageContainer} from "./Login.styled";
import React, {useState} from "react";
import ZauzooLogo from '../../logo_zauzoo.svg'


export default function LoginPage() {
    const [pickedUser, setPickedUser] = useState<string|undefined>(undefined)


    return (
        <LoginPageContainer>
            <LoginHeader setPickedUser={setPickedUser}/>
            <figure style={{margin: "2rem auto", width: 100, height: 100}}>
                <img src={ZauzooLogo} alt='Zauzoo logo' width="100%"/>
            </figure>
            <LoginForm pickedUser={pickedUser}/>
            <FooterBar/>
        </LoginPageContainer>
    )
}