import {useAuth} from "../hooks/authContext";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {ApplicationZones} from "../types";
import {hasAccess} from "../utils";
import NoRightsPage from "../pages/Error/NoRightsPage";


interface RouteProps {
    neededAccess: ApplicationZones
    component: ReactJSXElement
    pageName: string
}

export const ProtectedRoute = ({neededAccess, component, pageName}: RouteProps) => {
    const {accessRights, isAdmin} = useAuth();
    const pass = hasAccess(neededAccess.valueOf(), accessRights, isAdmin)

    return pass ? component : <NoRightsPage pageName={pageName}/>;
};