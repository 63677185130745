import React from 'react'

interface IPageProps {
    pageName: string
}
function NoRightsPage({pageName}:IPageProps) {
    return (
        <h1>You don`t have rights to see {pageName} page</h1>
    )
}

export default NoRightsPage
