import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import ListItemText from '@mui/material/ListItemText';

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #0f113d;
  &.active{
    color: #e46a69;
    & span{
      font-weight: 800;
    }
  }
`
export const StyledSubItemLabel = styled(ListItemText)`
padding: 5px 20px;
`
export const GroupLabel = styled(ListItemText)`
  color: #0f113d;
  padding: 5px 10px;
  & span {
    font-weight: 700;
  }
`