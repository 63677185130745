import React from 'react'
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";

function Loader() {
    return (
        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}>
            <CircularProgress color="secondary"/>
        </Box>
    )
}

export default Loader
