import Button from '@mui/material/Button';
import Person from "@mui/icons-material/Person";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/authContext";
import Divider from "@mui/material/Divider";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';import React from "react";
import Logout from "@mui/icons-material/Logout";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import {useLanguage} from "../../hooks/languageContext";
import MenuItem from "@mui/material/MenuItem";
import {Language} from "../../types";
import ExpandLess from "@mui/icons-material/ExpandLess";

export default function AuthMenu() {
    const navigate = useNavigate()
    const {user, logOut} = useAuth()
    const {languages, setPickedLanguage, pickedLanguage} = useLanguage()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
    const open = Boolean(anchorEl);

    function logoutUser() {
        logOut()
        navigate("/login")
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl !== null) {
            return
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handlePickLanguage(language: Language) {
        setPickedLanguage(language)
        localStorage.setItem('language_id', language.id.toString())
        handleClose()
        window.location.reload()
    }

    const activeLanguages = languages.filter(lang => lang.active)

    return (
        <>
            {user ?
                (<>
                        <Button variant="text" style={{color: "#fff"}} startIcon={<Person/>}>
                            {user.username}
                        </Button>
                        <div style={{display: "flex", gap: 20}}>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                startIcon={<LanguageOutlinedIcon/>}
                                endIcon={open?<ExpandLess/>:<ExpandMore/>}
                                sx={{color: 'secondary.main'}}
                            >
                                {pickedLanguage.languageFullName}
                            </Button>
                            <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {activeLanguages && activeLanguages.map(lang=>
                                        <MenuItem key={lang.id} onClick={()=>handlePickLanguage(lang)}>{lang.languageFullName}</MenuItem>)}
                                </Menu>
                            <Divider orientation="vertical" variant="middle" flexItem
                                     sx={{borderColor: 'primary.light'}}/>
                            <Button variant="text" sx={{color: "secondary.main"}}
                                    startIcon={<Logout/>}
                                    onClick={() => logoutUser()}>logout</Button>
                        </div>
                    </>

                )
                :
                <Button color="inherit">Login</Button>
            }
        </>
    )
}