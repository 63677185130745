import React, {useState} from 'react'
import {ContentBoxWrapper, ContentPaperWrapper, PageWrapper, PaperHeader} from "../../AppStyles";
import {FormControl, Grid, InputLabel, Tab, Tabs} from "@mui/material";
import {useAuth} from "../../hooks/authContext";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Translations from "./Translations";
import Languages from "./Languages";
import {useLanguage} from "../../hooks/languageContext";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Language} from "../../types";
import ClientAPI from "../../api/fuelcheckAPI";


function LanguagePage() {
    const {languages, pickedLanguage, setTranslations} = useLanguage()
    const [activeTab, setActiveTab] = useState<string>('1');
    // just for the select field so user can update translation but not change language in whole app
    const [pageLanguage, setPageLanguage] = useState<Language>(pickedLanguage);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };
    const activeLanguages = languages?.filter(l => l.active === true)

    function handleChangeLanguage(event: SelectChangeEvent) {
        const value = event.target.value;
        const picked = languages?.find(l => l.languageFullName === value)
        if (picked) setPageLanguage(picked);
    }

    function clearLanguageCache() {
        localStorage.removeItem("translations");
        ClientAPI.languages.getTranslations(pickedLanguage.id).then(translations => {
            setTranslations(translations)
            localStorage.setItem("translations", JSON.stringify(translations))
        })
    }

    return (
        <PageWrapper>
            <ContentBoxWrapper>
                <PaperHeader>
                    <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                        {/*LANGUAGE*/}
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth size="small">
                                <InputLabel id={"Language"}>Language</InputLabel>
                                <Select
                                    value={pageLanguage.languageFullName}
                                    label={"Language"}
                                    labelId="Language"
                                    onChange={handleChangeLanguage}
                                >
                                    {activeLanguages?.map((lang, i) =>
                                        <MenuItem value={lang.languageFullName}
                                                  key={i}>{lang.languageFullName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/*CLEAR CACHE*/}
                        <Grid item xs={12} md={4}>
                            <Button variant="contained" sx={{bgcolor: "secondary.main"}} fullWidth onClick={clearLanguageCache}>
                                Clear language cache
                            </Button>
                        </Grid>
                    </Grid>
                </PaperHeader>

                <ContentPaperWrapper>
                    <Tabs value={activeTab} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                        <Tab label="Website" value='1'/>
                        <Tab label="Languages" value='2'/>
                    </Tabs>
                    <Box sx={{overflowY: 'auto'}}>
                        <div hidden={activeTab !== '1'}>
                            <Translations pageLanguage={pageLanguage}/>
                        </div>
                        <div hidden={activeTab !== '2'}>
                            <Languages/>
                        </div>
                    </Box>
                </ContentPaperWrapper>
            </ContentBoxWrapper>
        </PageWrapper>
    )
}

export default LanguagePage
