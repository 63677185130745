import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {UserResponse} from "../types";
import ClientAPI from "../api/fuelcheckAPI";
import {parsePrivileges} from "../utils";

export type AuthTokens = {
    accessToken: string,
    refreshToken: string
}

type UserContextInfo = {
    username: string,
    country: string,
    defaultProfile: number|null,
    privileges: number | null,
    nickname: string
}

type AuthContextType = {
    user: UserContextInfo | null,
    logIn: (name: string, pass: string) => void;
    logOut: () => void;
    setUser: Dispatch<any>
    isLoggedIn: boolean
    setIsLoggedIn: Dispatch<SetStateAction<boolean>>
    isAdmin: boolean
    authTokens: AuthTokens | null,
    setAuthTokens: Dispatch<SetStateAction<AuthTokens | null>>
    isFetching: boolean,
    accessRights: number[]|[]
}

/** "null!" is needed for TS */
const AuthContext = createContext<AuthContextType>(null!);
export const useAuth = () => useContext(AuthContext)

export function AuthProvider({children}: { children: ReactNode }) {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<UserContextInfo | null>(null);
    const [isAdmin, setAdmin] = useState<boolean>(false);
    const [authTokens, setAuthTokens] = useState<AuthTokens | null>(persistTokens());
    const [isFetching, setIsFetching] = useState(false);
    const [accessRights, setAccessRights] = useState<number[]>([]);

    useEffect(() => {
        //checks user after refreshing page
        const accessToken = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (accessToken && refreshToken) {
            setIsFetching(true)

            ClientAPI.auth.getLoggedUser().then(res => {
                console.log("res in getUser auth context", res)

                if (res?.status === 200) {
                    const {username, country, defaultProfile, privileges, nickname, isAdmin}: UserResponse = res.data;
                    const user: UserContextInfo = {
                        username: username,
                        country: country,
                        defaultProfile: defaultProfile,
                        privileges: privileges,
                        nickname: nickname
                    }
                    setUser(user);

                    const rights = parsePrivileges(privileges)
                    setAccessRights(rights)

                    if (isAdmin) setAdmin(true)
                    setIsLoggedIn(true)
                } else {
                    logOut()
                }
            }).finally(() => setIsFetching(false))
        }
    }, [])

    function persistTokens() {
        const accessToken = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken && accessToken) {
            const tokens: AuthTokens = {
                accessToken: accessToken,
                refreshToken: refreshToken
            }
            return tokens
        } else {
            return null
        }
    }

    const logIn = (name: string, pass: string) => {
        setIsFetching(true)

       ClientAPI.auth.login({username: name, password: pass}).then(res => {
            if (res && res.status === 200) {
                console.log(res.data)
                const {username, country, defaultProfile, privileges, nickname, isAdmin}: UserResponse = res.data;
                const user: UserContextInfo = {
                    username: username,
                    country: country,
                    defaultProfile: defaultProfile,
                    privileges: privileges,
                    nickname: nickname
                }
                setUser(user);
                if (isAdmin) setAdmin(true)

                const rights = parsePrivileges(privileges)
                setAccessRights(rights)

                const tokens: AuthTokens = {
                    accessToken: res.data.access_token,
                    refreshToken: res.data.refresh_token
                }
                setAuthTokens(tokens);
                setIsLoggedIn(true)


                localStorage.setItem("token", JSON.stringify(res.data.access_token));
                localStorage.setItem("refresh_token", JSON.stringify(res.data.refresh_token));
            }
        }).finally(()=>setIsFetching(false))

    }

    function logOut() {
        console.warn("logging out");
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        setUser(null)
        setIsLoggedIn(false)
        setAdmin(false)
        setAuthTokens(null)
        setAccessRights([])
    }


    const value = {
        user, logIn, logOut, isLoggedIn, setIsLoggedIn, setUser, isAdmin, setAuthTokens, authTokens, isFetching,accessRights
    }

    return <AuthContext.Provider value={value}>{isFetching ? null : children}</AuthContext.Provider>;
}

