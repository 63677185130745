import React, {Suspense, useState} from 'react';
import {Outlet} from "react-router-dom";
import AuthMenu from './AuthMenu';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useAuth} from "../../hooks/authContext";
import NavigationListItem from "./NavigationListItem";
import MenuGroup from "./MenuGroup";
import ListItemButton from "@mui/material/ListItemButton";
import {StyledSubItemLabel} from "./NavigationMenu.styled";
import ListItem from "@mui/material/ListItem";
import {ChangePasswordDialog} from "../Forms/ChangePasswordDialog";
import LoadingFallback from "../Loading/LoadingFallback";
import {hasAccess} from "../../utils";
import {ApplicationZones} from "../../types";
import ProtectedNavListItem from "./ProtectedNavListItem";
import {useTranslation} from "../../hooks/useTranslation";

export default function NavigationMenu() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const {isLoggedIn, user, isAdmin, accessRights} = useAuth()
    const translate = useTranslation()
    const drawerWidth = 240;
    const [openChangePasswordDiaolg, setOpenChangePasswordDiaolg] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const hasIntegrationRights = hasAccess(ApplicationZones.DK.valueOf(), accessRights, isAdmin) ||
        hasAccess(ApplicationZones.DH.valueOf(), accessRights, isAdmin)

    const drawer = (
        <Box>
            <Toolbar sx={{bgcolor: "secondary.main", position: 'sticky', top:0, zIndex: 10}}>
                <Typography variant="h4" component="div" sx={{flexGrow: 1, color: "#fff",}}>
                    Fuel Check
                </Typography>
            </Toolbar>

            <Divider/>
            <Box className={'menu list box'} sx={{overflowY: "auto"}}>
                <MenuGroup groupName={"Generic"} closeMenu={handleDrawerToggle}>
                    <NavigationListItem path={"/"} title={'Dashboard'}/>
                    <ProtectedNavListItem path={"/report"} title={translate('mainMenu.menuItem.general.report')}
                                          rights={ApplicationZones.DT}/>
                    <ProtectedNavListItem path={"/diet_report"} title={translate('mainMenu.menuItem.general.driverReport')}
                                          rights={ApplicationZones.DA}/>
                    <ProtectedNavListItem path={"/driver_activity_report"} title={translate('mainMenu.menuItem.general.driverActivityReport')}
                                          rights={ApplicationZones.DA}/>
                    <ProtectedNavListItem path={"/logbook"} title={translate('report.design.logbook.title')}
                                          rights={ApplicationZones.LB}/>
                    <ProtectedNavListItem path={"vehicle/positions"} title={translate('mainMenu.menuItem.general.vehicleReport')}
                                          rights={ApplicationZones.VP}/>
                    <ProtectedNavListItem path={"/admin_report"} title={translate('mainMenu.menuItem.general.adminReport')}
                                          rights={ApplicationZones.ADMIN}/>
                </MenuGroup>
                <Divider/>
                {hasIntegrationRights &&
                    <>
                        <MenuGroup groupName={"Integrations"} closeMenu={handleDrawerToggle}>
                            <ProtectedNavListItem path={"denmark"} title={translate('mainMenu.menuItem.settings.denmarkUploader')}
                                                  rights={ApplicationZones.DK}/>
                            {/*<NavigationListItem path={"/"} title={'Gefco uploader'}/>*/}
                            {/*<NavigationListItem path={"/"} title={'GefcoV2 uploader'}/>*/}
                            <ProtectedNavListItem path={"/dhl"} title={translate('mainMenu.menuItem.settings.dhlUploader')}
                                                  rights={ApplicationZones.DH}/>
                            {/*<NavigationListItem path={"/"} title={'DHL PL uploader'}/>*/}
                        </MenuGroup>
                        <Divider/>
                    </>
                }
                <MenuGroup groupName={"Settings"} closeMenu={handleDrawerToggle}>
                    <ProtectedNavListItem path={"/profiles"} title={translate('mainMenu.menuItem.settings.profiles')}
                                          rights={ApplicationZones.DT}/>
                    <ProtectedNavListItem path={"/diets"} title={translate('mainMenu.menuItem.settings.diets')}
                                          rights={ApplicationZones.DA}/>
                    <NavigationListItem path={"/depots"} title={translate('mainMenu.menuItem.settings.settingDepos')}/>
                    <NavigationListItem path={"/vehicles"} title={translate('mainMenu.menuItem.settings.settingVehicles')}/>

                    <ListItem disablePadding onClick={() => setOpenChangePasswordDiaolg(true)}>
                        <ListItemButton sx={{padding: 0}}>
                            <StyledSubItemLabel primary={translate('setting.user.table.password')}/>
                        </ListItemButton>
                    </ListItem>
                </MenuGroup>
                <Divider/>
                {hasAccess(ApplicationZones.ADMIN.valueOf(), accessRights,isAdmin) &&
                    <MenuGroup groupName={"Administration "} closeMenu={handleDrawerToggle}>
                        <ProtectedNavListItem path={'/languages'} title={translate('mainMenu.menuItem.settings.languages')}
                                              rights={ApplicationZones.ADMIN}/>
                        <ProtectedNavListItem path={"/users"} title={translate('setting.user.title')}
                                              rights={ApplicationZones.ADMIN}/>
                        <ProtectedNavListItem path={"/mail"} title={translate('setting.mail.title')}
                                              rights={ApplicationZones.ADMIN}/>
                        <ProtectedNavListItem path={"/conversions"} title={translate('mainMenu.menuItem.settings.conversion')}
                                              rights={ApplicationZones.ADMIN}/>
                        <ProtectedNavListItem path={"/tranv"} title={'TRANV'}
                                              rights={ApplicationZones.ADMIN}/>
                    </MenuGroup>
                }
            </Box>
        </Box>

    )

    return (
        <Box sx={{display: 'flex'}}>
            {isLoggedIn &&
                <>
                    <AppBar position="fixed"
                            sx={{
                                width: {md: `calc(100% - ${drawerWidth}px)`},
                                ml: {md: `${drawerWidth}px`},
                            }}
                    >
                        <Toolbar>
                            <IconButton color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{mr: 2, display: {md: 'none'}}}
                            >
                                <MenuIcon sx={{display: {xs: 'inline-block', md: 'none'}}}/>
                            </IconButton>
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                <AuthMenu/>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/*LEFT BAR MENU*/}
                    <Box component="nav"
                         sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}
                    >
                        {/*MOBILE*/}
                        <Drawer variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                                }}
                        >
                            {drawer}
                        </Drawer>
                        {/*DESKTOP*/}
                        <Drawer variant="permanent"
                                open
                                sx={{
                                    display: {xs: 'none', md: 'block'},
                                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                                }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    {/**********************************/}
                </>}
            <Box component="main"
                 sx={{flexGrow: 1, padding: "0 10px", width: {xs: "100%", lg: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>
                {/*suspense here for lazy loading*/}
                <Suspense fallback={<LoadingFallback drawerWidth={drawerWidth}/>}>
                    <Outlet/>
                </Suspense>
                {/*set ts-ignore because when this is rendered there will be user obviously*/}
                {/*@ts-ignore*/}
                <ChangePasswordDialog open={openChangePasswordDiaolg} user={user.username}
                                      closeDialog={setOpenChangePasswordDiaolg}/>
            </Box>
        </Box>
    )
}

