import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";


/**
 * PAGE MAIN STRUCTURE(exclude Conversion Table Page)
 * All page is wrapped to a PageWrapper.
 *
 **IF YOU WOULD HAVE A SELECTS
 *--Then use ContentBoxWrapper to wrap a Header
 *******AND TABLE TO VIEW DATA
 * ------add PaperTableWrapper
 * TODO: add overflow to PaperTableWrapper -> TableContainer as default.
 *-------TableContainer must have a 'overflow-y: "auto"' to have a scroll!!!
 *
 **IF YOU WOULD NEED ANOTHER OR ONLY PAPER COMPONENT
 *--Then use ContentPaperWrapper where you can PASS A HEIGHT.
 *******************************************
 * EXAMPLE OF PAGE - DHLUploaderPage.tsx
 *
 * flexbox(column) with fixed height. PageWrapper
 *      - flexbox(column) can pass height in %(flex-basis). ContentBoxWrapper
 *          -- block admin header. PaperAdminHeader
 *          -- flexbox(column) wrapper. PaperTableWrapper
 *              --- table header
 *              --- table with scroll
 *      - flexbox(column) can pass height in %(flex-basis). ContentPaperWrapper
 *          -- table header
 *          -- table with scroll
 */

export const PageWrapper = styled(Box)`
  max-height: 90vh; // fix dynamic height
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-top: 1rem;
`
export const ContentBoxWrapper = styled(Box)<{ basis?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  margin-bottom: 1rem;
  //border-radius: 0.5rem;
  flex-basis: ${props => props.basis ?? "100%"};
`

export const ContentPaperWrapper = styled(Paper)<{ basis?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  padding: 1rem;
  border-radius: 0.5rem;
  flex-basis: ${props => props.basis ?? "100%"};
`

export const PaperTableWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  padding: 1rem;
  border-radius: 0.5rem;
`
export const BoxTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  //padding: 1rem;
  border-radius: 0.5rem;
`

export const PaperHeader = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
`
