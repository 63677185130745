import React, {ChangeEvent, useState} from 'react'
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {TranslationUpdateDto} from "../../types";
import {useMutation} from "@tanstack/react-query";
import ClientAPI from "../../api/fuelcheckAPI";

interface IRowProps {
    baseTextId: number,
    defaultTranslation: string,
    translation: string|undefined,
    langId: number
}


function TranslationRow(props: IRowProps) {
    const {baseTextId, langId, defaultTranslation, translation} = props
    const [translationValue, setTranslationValue] = useState(translation ?? '');

    function handleChangeValue(evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const value = evt.target.value;
        setTranslationValue(value);
    }

    const translationUpdate = useMutation({
        mutationFn: (newTranslation: TranslationUpdateDto) => ClientAPI.languages.updateTranslation(newTranslation),
    })

    const {isPending, mutate} = translationUpdate

    function generateDto(baseId: number, value: string) {
        return {
            id: {
                languageId: langId,
                baseTextId: baseId
            },
            translation: value
        }

    }

    return (
        <Grid container spacing={2} sx={{paddingY: "5px"}}>
            <Grid item xs={6}>
                <Typography component="p">
                    {defaultTranslation}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField variant="outlined" fullWidth size="small" name="translation"
                           disabled={isPending}
                           value={translationValue}
                           onChange={handleChangeValue}
                           onBlur={() => mutate(generateDto(baseTextId, translationValue))}
                />
            </Grid>
        </Grid>
    )
}

export default TranslationRow
