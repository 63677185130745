import {ApplicationZones} from "./types";

/** this number is base value for privileges from previous version*/
export const ADDITIONAL_NUM = 128;

const rights = (Object.keys(ApplicationZones) as (keyof typeof ApplicationZones)[])
    .map(key => Number(ApplicationZones[key]))
    .sort((a, b) => b - a) // must be sorted from higher number

/**
 * Parse privileges from user when edit user
 * for example from privileges 644-ADDITIONAL_NUM we get 512, 2, 1
 * **/
export function parsePrivileges(userPrivileges: number) {
    userPrivileges -= ADDITIONAL_NUM;
    let privileges: number[] = [];

    for (let value of rights) {
        if (userPrivileges - value >= 0) {
            privileges.push(value);
            userPrivileges -= value;
        }
    }
    return privileges
}

export function hasAccess(neededAccess: number, hasRights: number[], isAdmin:boolean):boolean {
    // need this because now in DB is a mistake that admin=true but privileges=null
    // so for now it`s hot fix how to check if user has correct Administrator rights
    const hasAdminAccess = hasRights.includes(10000) && isAdmin
    return hasRights.includes(neededAccess) || hasAdminAccess
}