import {useLanguage} from "./languageContext";
import {BaseText} from "../types";

export const useTranslation = () => {
    // translations are already in picked language
    const {translations, baseTexts} = useLanguage();

    const translate = (base: string) => {
            //check if passed valid base text
            const validBaseText = baseTexts.find((bt: BaseText) => bt.baseText === base);
            // console.log(`validBaseText for ${base}`, validBaseText);
            if (validBaseText) {
                // console.log("translations list", translations.sort((a, b) => a.baseTextId - b.baseTextId));
                const translation = translations.find(t => t.baseTextId === validBaseText.id);
                // console.log(`translation of ${base}`, translation)
                if (translation?.translation && translation?.translation !== '') {
                    // has translation
                    return translation.translation;
                } else {
                    // if translation is not added
                    return validBaseText.defaultTranslation
                }
            } else {
                // console.warn(`Provided base text '${base}' not found`)
                return base + '404';
            }
    }

    return translate
}