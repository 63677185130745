import {Navigate} from 'react-router-dom';
import {useAuth} from "../hooks/authContext";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";


interface RouteProps {
    component: ReactJSXElement
    redirectTo?: string
}

/** - If the route is private and the user is logged in, render the component
 * - Otherwise render <Navigate> to redirectTo
 */
export const PrivateRoute = ({component, redirectTo = '/login'}: RouteProps) => {
    const {isLoggedIn, isFetching,logOut} = useAuth();
    const shouldRedirect = !isLoggedIn && !isFetching;
    const token = localStorage.getItem("token")
    if (token===null&& isLoggedIn) logOut()
    return shouldRedirect ? <Navigate to={redirectTo}/> : component;
};