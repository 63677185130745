import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, {memo, SyntheticEvent, useEffect, useState} from "react";
import ClientAPI from "../../api/fuelcheckAPI";
import {useAuth} from "../../hooks/authContext";
import { useSearchParams } from "react-router-dom";
import {useTranslation} from "../../hooks/useTranslation";
interface Props {
    onUsernameChange: (name: string) => void,
    additionalName?: string
    // NOT OPTIONAL!!! set to optional only because of TS. In some period of time can be type undefined but in mostly have value
    // need country for display users only in that country
    country?: string
}


// TODO: maybe re-write it into HOC
const CompanySelect = memo(function CompanySelect({onUsernameChange, additionalName, country}: Props) {
    const {user, isAdmin} = useAuth()
    const [usernames, setUsernames] = useState<string[]>([]);
    const [pickedUsername, setPickedUsername] = useState<string | null>(null);
    const [userInputValue, setUserInputValue] = useState<string>("");
    const [searchParams] = useSearchParams();
    const usernameFromUrl = searchParams.get("username");
    const translate = useTranslation()

    useEffect(() => {
        const pickedCountry = country ? country : user?.country

        if (pickedCountry) {
            setPickedUsername(null)
            onUsernameChange('')
            ClientAPI.users.getUsernamesList(pickedCountry).then((resp: string[]) => {
                const sortedNames = resp.sort()
                setUsernames(sortedNames);
                // if user is not administrator then set pickedUsername by default username of user that logged in
                if (!isAdmin) {
                    const searchedUsername = sortedNames.find(username => username === user?.username);
                    if (searchedUsername) {
                        setPickedUsername(searchedUsername);
                        onUsernameChange(searchedUsername);
                    }
                }
                // now used in diet rates page only for automatically set "DEFAULT RATES".
                if (additionalName) {
                    setPickedUsername(additionalName);
                    onUsernameChange(additionalName);
                }
                // used for getting back from InspectVehiclePage to avoid re-pick username
                if (usernameFromUrl){
                    const searchedUsername = sortedNames.find(username => username === usernameFromUrl);
                    if (searchedUsername) {
                        setPickedUsername(searchedUsername);
                        onUsernameChange(searchedUsername);
                    }
                }
            });
        }
    }, [isAdmin, user?.username, country, onUsernameChange, user?.country, additionalName, usernameFromUrl]);


    function handlePickUser(event: SyntheticEvent<Element, Event>, newValue: string | null) {
        if (newValue !== null) {
            setPickedUsername(newValue);
            onUsernameChange(newValue);
        }
    }

    function handleChangeUserInput(event: SyntheticEvent<Element, Event>, newValue: string) {
        setUserInputValue(newValue);
    }

    return (
        <Autocomplete
            size="small"
            disablePortal
            value={pickedUsername}
            onChange={handlePickUser}
            inputValue={userInputValue}
            onInputChange={handleChangeUserInput}
            options={additionalName ? [additionalName, ...usernames] : usernames}
            fullWidth
            renderInput={(params) => <TextField {...params} label={translate('report.design.logbook.company')}/>}
        />
    );
})
export default CompanySelect;