import {useAuth} from '../hooks/authContext';
import {Navigate} from 'react-router-dom';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

/**
 * - If the route is restricted and the user is logged in, render a <Navigate> to redirectTo
 * - Otherwise render the component
 */
interface RouteProps {
    component: ReactJSXElement
    redirectTo: string
}

export const RestrictedRoute = ({component, redirectTo = '/'}: RouteProps) => {
    const {isLoggedIn, logOut} = useAuth();
    const token = localStorage.getItem("token")
    if (token === null && isLoggedIn) logOut()
    return token && isLoggedIn ? <Navigate to={redirectTo}/> : component;
};
