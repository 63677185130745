import React from 'react'
import {Backdrop, CircularProgress} from "@mui/material";

type FallbackProps = {
    drawerWidth: number
}
function LoadingFallback({drawerWidth}:FallbackProps) {
    return (
        <Backdrop
            sx={{ color: '#fff', marginLeft:{sx:"0", lg:`${drawerWidth}px`} }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default LoadingFallback
