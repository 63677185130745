import React from 'react'
import Box from "@mui/material/Box";
import {useQuery} from "@tanstack/react-query";
import ClientAPI from '../../api/fuelcheckAPI';
import Loader from "../../components/Loading/Loader";
import TranslationRow from "./TranslationRow";
import {Language} from "../../types";
import {useLanguage} from "../../hooks/languageContext";

interface IProps {
    pageLanguage: Language
}

function Translations({pageLanguage}: IProps) {
    const {baseTexts} = useLanguage()

    const {data: translations, isFetching } = useQuery({
            queryFn: () => ClientAPI.languages.getTranslations(pageLanguage.id),
            queryKey: ['translations', pageLanguage.id],
            enabled: !!pageLanguage.id,
            refetchOnWindowFocus: false,
        }
    )
    console.time("translations mapping")
    const translationsDict = translations?.reduce((acc, translation) => {
        acc[translation.baseTextId] = translation.translation;
        return acc;
    }, {} as Record<number, string>);
    console.timeEnd("translations mapping")
    return (
        <Box sx={{padding: '10px'}}>
            {isFetching ?
                <Loader/>
                :
                baseTexts?.map((item) =>
                    <TranslationRow key={item.id}
                                    translation={translationsDict ? translationsDict[item.id] : undefined}
                                    langId={pageLanguage.id}
                                    baseTextId={item.id}
                                    defaultTranslation={item.defaultTranslation}/>
                )
            }
        </Box>
    )
}

export default Translations
