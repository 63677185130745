import React from 'react'
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel} from "@mui/material";
import {useLanguage} from "../../hooks/languageContext";


function Languages() {
    const {languages} = useLanguage()

    function handleChangeActiveLanguage(id: number) {
        console.log(id)
        //TODO: change state
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', padding: '10px'}}>
            {languages?.map((lang) =>
                <FormControlLabel
                    key={lang.id}
                    control={<Checkbox color={"secondary"} checked={lang.active}
                                       onChange={() => handleChangeActiveLanguage(lang.id)}/>
                    }
                    label={lang.languageFullName}
                />
            )}
        </Box>
    )
}

export default Languages
