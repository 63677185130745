import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0f113d',
            light: '#a2adc0',
            contrastText: '#fff'
        },
        secondary: {
            main: '#e46a69',
            contrastText: '#fff'
        },
    },
});