import React from 'react'
import NavigationListItem, {NavigationListItemProps} from "./NavigationListItem";
import {ApplicationZones} from "../../types";
import {useAuth} from "../../hooks/authContext";
import {hasAccess} from "../../utils";

type ProtectedLinkProps = {
    rights: ApplicationZones,
} & NavigationListItemProps
export default function ProtectedNavListItem({rights,path,title,}:ProtectedLinkProps) {
    const {accessRights, isAdmin} = useAuth()

    const showLink = hasAccess(rights.valueOf(), accessRights, isAdmin)

    return (
        <>
            {showLink && <NavigationListItem path={path} title={title}/>}
        </>
    )
}
