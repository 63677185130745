import React from 'react'
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {StyledLink, StyledSubItemLabel} from "./NavigationMenu.styled";

export type NavigationListItemProps = {
    path: string,
    title: string
}

function NavigationListItem({path, title}: NavigationListItemProps) {
    return (
        <StyledLink to={path}>
            <ListItem disablePadding>
                <ListItemButton sx={{padding: 0}}>
                    <StyledSubItemLabel primary={title}/>
                </ListItemButton>
            </ListItem>
        </StyledLink>
    )
}

export default NavigationListItem
