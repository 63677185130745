import axios from "axios";
import {
    BaseText,
    BasicCustomerInfoState, CustomerInfo, Depot, DietRateRequestDto, DietValidityInterval,
    IdleSettings, Language,
    LoginData,
    ReportConversion,
    ReportConversionTypes, TranslationDto, TranslationUpdateDto, TranVVehicle, TruckRequestBody,
    UserInfo, Vehicle,
    VehicleInfo, VehiclePositionRow, VehicleProfileDto
} from "../types";
import {DrivingTechniqueReport} from "../pages/TechniqueReport/TechniqueReportPage";


axios.defaults.baseURL = 'https://test.fuelcheck.eu/rest'
export const axiosPrivate = axios.create({
        baseURL: axios.defaults.baseURL
    }
);

const persistedAccessToken = localStorage.getItem("token");
axios.defaults.headers.common.Authorization = persistedAccessToken ? `Bearer ${JSON.parse(persistedAccessToken)}` : null


axios.interceptors.request.use(async req => {
    const persistedAccessToken = localStorage.getItem("token");
    if (persistedAccessToken) {
        req.headers.Authorization = `Bearer ${JSON.parse(persistedAccessToken)}`
    }
    // when token don't exist like in login request then just send a request
    return req;
}, error => Promise.reject(error));

axios.interceptors.response.use(resp => resp,
    async err => {
        const prevRequest = err?.config;
        if ((err?.response?.status === 403 || err?.response?.status === 401) && !prevRequest?.sent) {
            prevRequest.sent = true;
            const persistedRefreshToken = localStorage.getItem("refresh_token");

            if (!persistedRefreshToken) {
                // window.history.pushState("session expired", "session expired", "/login")
                // window.history.back()
                window.location.assign('/login')
                return err
            }

            //if refresh_token exist set it to auth header in another axios instance(to avoid cycle fetch)
            // and fetch new access token
            axiosPrivate.defaults.headers.common.Authorization = `Bearer ${JSON.parse(persistedRefreshToken)}`
            const newAccessToken = await axiosPrivate.post("/auth/refresh")
                .then(res => res.data.access_token)
                .catch(() => {
                    // if refresh_token also expired
                    //TODO: implement some specific err handling + notification
                    // that user can understand why he is redirected to "/login"
                    localStorage.removeItem("token");
                    localStorage.removeItem("refresh_token")
                    axios.defaults.headers.common.Authorization = null
                    prevRequest.headers.Authorization = null
                    window.history.pushState("session expired", "session expired", "/login")
                    window.history.back()
                    alert("Your session was expired. Please login")
                })

            if (newAccessToken) {
                localStorage.setItem("token", JSON.stringify(newAccessToken));
                prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return axios(prevRequest);
            } else {
                return err;
            }
        }
    })


/////////////////////////////////////////////////
//////////////// A U T H ////////////////////////
/////////////////////////////////////////////////

async function login(credentials: LoginData) {
    try {
        return await axios.post('/auth/login', credentials);
    } catch (e) {
        console.error(e)
    }
}


async function getLoggedUser() {
    try {
        const response = await axios.get('/auth/current')
        return response
    } catch (e) {
        console.error("while getting user in fuelcheckApi", e)
    }
}


// async function logout() {
//     try {
//         return await axios.post('/logout')
//     } catch (e) {
//         console.error("while logout user in fuelcheckApi", e)
//     }
// }

/////////////////////////////////////////////////
//////////////// V E H I C L E S ////////////////
/////////////////////////////////////////////////
async function getVehicleList(username: string, isDeleted = false) {
    try {
        const {data} = await axios.get(`/vehicles/${username}/${isDeleted}`);
        return data;
    } catch (err) {
        console.log("Fail to get vehicles by ", username, isDeleted, err);
        return err;
    }
}

const updateProfileInVehicle = async (vehicle: VehicleInfo): Promise<VehicleProfileDto> => await axios.put(`/vehicles`, vehicle)

async function getVehicle(username: string, vin: string) {
    try {
        const {data} = await axios.get(`/vehicles?username=${username}&vin=${vin}`)
        return data
    } catch (e) {
        console.error('error while get vehicle by vin', e)
    }
}

async function getVehicleStatus(fromDate: string, toDate: string, vehicleId: number) {
    try {
        const {data} = await axios.get(`/vehicle/status/?from=${fromDate}&to=${toDate}&vehicleId=${vehicleId}`)
        return data
    } catch (e) {
        console.error('while get vehicle status', e)
    }
}

async function getVehiclePosition(vehicleId: string) {
    try {
        const {data} = await axios.get(`/vehicle/position/?vehicleId=${vehicleId}`)
        return data as VehiclePositionRow[]
    } catch (e) {
        console.error('while get vehicle position', e)
    }
}

async function getVehiclesByUsername(username: string, isDeleted: boolean) {
    try {
        const {data} = await axios.get(`/vehicles/${username}/${isDeleted}`);
        return data
    } catch (e) {
        console.error("Fail to get vehicle data by username", username, e);
    }
}

const getVehicleByVin = async (vin:string)=> await axios.get<Vehicle>(`/vehicles/vin/${vin}`).then(res=>res.data)

const getVehicleByDisplayName = async (displayName:string)=> await axios.get<Vehicle>(`/vehicles/regno/${displayName}`).then(res=>res.data)


////////////////////////////////////////////////////
//////////////// D R I V E R S /////////////////////
///////////////////////////////////////////////////

async function getDriversByCustomer(username: string) {
    try {
        const {data} = await axios.get(`/drivers/${username}`);
        return data
    } catch (e) {
        console.error(e)
    }
}


async function getDrivingTechniqueReport(fromDate: string, toDate: string, name: string) {
    try {
        const {data} = await axios.get<DrivingTechniqueReport>(`/report/list?from=${fromDate}&to=${toDate}&username=${name}`)
        return data;
    } catch (e) {
        console.error('while driving tech report', e)
    }
}

async function getBasicCustomerInfo() {
    try {
        const {data} = await axios.get<BasicCustomerInfoState>(`/dashboard/apiInfo`)
        return data;
    } catch (e) {
        console.error('while get customer info', e)
    }
}

///////////////////////////////////////////////////
//////////////// U S E R S ////////////////////////
///////////////////////////////////////////////////

// async function getAllUsers() {
//     try {
//         const {data} = await axios.get('/users');
//         return data;
//     } catch (e) {
//         console.error('Fail get all users', e);
//     }
// }

const getAllUsers = () => axios.get<UserInfo[]>('/users').then(res => res.data)


async function getUsernamesList(country: string) {
    try {
        const {data} = await axios.get(`/users/names/${country}`);
        return data;
    } catch (error) {

        // console.error("Fail to get usernames list", e);
    }
}

async function getUser(username: string) {
    try {
        const {data} = await axios.get(`/users/${username}`);
        return data;
    } catch (e) {
        console.error(`Fail to get User: ${username}`, e);
    }
}

const updateUserInfo = (user: UserInfo) => axios.put(`/users`, user).then(resp => resp.data)

const deleteUser = (username: string) => axios.delete(`/users/${username}`)

const createUser = (user: UserInfo) => axios.post('/users', user)


/////////////////////////////////////////////////////
///////////// C U S T O M E R S //////////////////////
/////////////////////////////////////////////////////

async function getAllCustomersNames() {
    try {
        const {data} = await axios.get('/customers');
        return data;
    } catch (e) {
        console.error('Fail get all customers', e);
    }
}

const createCustomer = (customer: CustomerInfo) => axios.post('/customers', customer)


////////////////////////////////////////////////////////////////////////////
//////////////// R E P O R T _ C O N V E R S I O N S //////////////////////
///////////////////////////////////////////////////////////////////////////

async function getAllReportConversions(profileId: string) {
    try {
        return await axios.get(`/conversions/${profileId}`);
    } catch (e) {
        console.error("Failed to get all Report Conversions", e)
    }
}

async function addReportConversion(conversion: ReportConversion) {
    try {
        return await axios.post("/conversions", conversion)
    } catch (e) {
        console.error("Failed to add conversion", conversion, e);
    }
}

async function findAllConversionsByType(conversionType: ReportConversionTypes, profileId: number) {
    try {
        const {data} = await axios.get(`/conversions/${profileId}/types/${conversionType}`);
        return data
    } catch (e) {
        console.error("Failed to get conversions", e);
    }
}

async function updateReportConversion(conversion: ReportConversion) {
    try {
        const {data} = await axios.put(`/conversions/${conversion.id}`, conversion);
        return data;
    } catch (e) {
        console.error("Fail tro update report conversion", conversion, e);
    }
}

////////////////////////////////////////////////////////
//////////////// C U R R E N C Y //////////////////////
//////////////////////////////////////////////////////
async function getCurrencyList() {
    try {
        const {data} = await axios.get("/currencies");
        return data;
    } catch (e) {
        console.error("Fail to get currencies list", e);
    }
}

////////////////////////////////////////////////////////
//////////////// C O U N T R Y //////////////////////
//////////////////////////////////////////////////////
async function getCountriesList() {
    try {
        const {data} = await axios.get("/countries");
        return data;
    } catch (e) {
        console.error("Fail to get countries list", e);
    }
}

////////////////////////////////////////////////////////
//////////////// D I E T _ R A T E S  //////////////////
//////////////////////////////////////////////////////
async function getDiets(country: string, username: string, validityInterval: string) {
    try {
        const {data} = await axios.get(`/diet_rates/${country}/${username}/${validityInterval}`);
        return data;
    } catch (e) {
        console.error("Fail to get diets list", e);
    }
}

async function updateDietRate(data: DietRateRequestDto) {
    try {
        await axios.put('/diet_rates', data);
    } catch (e) {
        console.error("Fail to update diet rate", e);
    }
}

async function setDietRate(data: DietRateRequestDto) {
    try {
        await axios.post('/diet_rates', data);
    } catch (e) {
        console.error("Fail to set diet rate", e);
    }
}

async function deleteDietRate(id: number) {
    try {
        await axios.delete(`/diet_rates/${id}`);
    } catch (e) {
        console.error("Fail to set diet rate", e);
    }
}

////////////////////////////////////////////////////////////////////////
//////////////// V A  L I D I T Y _ I N T E R V A L S  /////////////////
////////////////////////////////////////////////////////////////////////
async function getAllValidityIntervals(country: string, username: string) {
    try {
        const {data} = await axios.get(`/validity_intervals/${country}/${username}`);
        return data;
    } catch (e) {
        console.error("Fail to get ValidityIntervals ", e);
    }
}

async function updateValidityInterval(dto: DietValidityInterval,) {
    try {
        const {data} = await axios.put(`/validity_intervals`, {...dto})
        return data;
    } catch (e) {

        console.error("Fail to update ValidityIntervals ", e);
    }
}

async function deleteValidityInterval(id: number) {
    try {
        await axios.delete(`/validity_intervals/${id}`,)
    } catch (e) {
        console.error("Fail to delete ValidityIntervals ", e);
    }
}

async function createValidityInterval(interval: DietValidityInterval) {
    try {
        const {data} = await axios.post(`/validity_intervals`, {...interval});
        return data;
    } catch (e) {
        console.error("Fail to create ValidityIntervals ", e);
    }
}

///////////////////////////////////////////////////////////////////////
//////////////// V E H I C L E _ P R O F I L E S //////////////////////
///////////////////////////////////////////////////////////////////////

// async function getAllProfiles() {
//     try {
//         const {data} = await axios.get('/vehicleProfiles');
//         return data;
//     } catch (e) {
//         console.error("Fail to get vehicle profiles", e);
//     }
// }

const getAllProfiles = (): Promise<VehicleProfileDto[]> => axios.get<VehicleProfileDto[]>('/vehicleProfiles').then(res => res.data)

async function getVehicleProfileByUsername(username: string) {
    try {
        const {data} = await axios.get(`/vehicleProfiles/users/${username}`);
        return data;
    } catch (e) {
        console.error("Fail to get vehicle profile by username", username, e);
    }
}

////////////////////////////////////////////////////////
//////////////// D E P O T S //////////////////////
//////////////////////////////////////////////////////


async function getDepotsByUsername(username: string) {
    try {
        const {data} = await axios.get(`/depots/${username}`);
        return data;
    } catch (e) {
        console.error(`Fail to get depots by ${username}`, e);
    }
}

async function createDepot(depot: Depot) {
    try {
        const {data} = await axios.post(`/depots`, depot);
        return data;
    } catch (e) {
        console.error(`Fail to create depot ${depot}`, e);
    }
}

async function updateDepot(depot: Depot) {
    try {
        const {data} = await axios.put(`/depots`, depot);
        return data;
    } catch (e) {
        console.error(`Fail to update depot ${depot}`, e);
    }
}

async function deleteDepot(depotId: number) {
    try {
        await axios.delete(`/depots/${depotId}`);
    } catch (e) {
        console.error(`Fail to delete depot by ID: ${depotId}`, e);
    }
}

////////////////////////////////////////////////////////////////
//////////////// I D L E _ S E T T I N G S /////////////////////
////////////////////////////////////////////////////////////////

async function getIdleSettings(username: string) {
    try {
        const {data} = await axios.get(`/depot-idle-settings?username=${username}`);
        return data;
    } catch (e) {
        console.error("Fail to get idle setting for " + username, e);
    }
}

async function setIdleSetting(idle: IdleSettings) {
    try {
        await axios.post('/depot-idle-settings', idle)
    } catch (e) {
        console.error("Fail to set idle settings", idle, e);
    }
}

///////////////////////////////////////////////////
//////////////// D E N M A R K ////////////////////
///////////////////////////////////////////////////

async function getTruckByUsername(username: string) {
    try {
        const {data} = await axios.get(`/denmark/${username}`);
        return data;
    } catch (e) {
        console.error("Fail to get trucks by username:", username, e)
    }

}

async function deleteHookUp(vehicleId: number) {
    try {
        return await axios.delete(`/denmark?id=${vehicleId}`);
    } catch (e) {
        console.error("Fail to delete hook-up by id:", vehicleId, e)
    }
}

async function addHookUp(truck: TruckRequestBody) {
    try {
        const {data} = await axios.post(`/denmark`, truck);
        return data;
    } catch (e) {
        console.error("Fail to add hook-up:", truck, e)
    }
}

async function updateHookUp(truck: TruckRequestBody) {
    try {
        const {data} = await axios.put(`/denmark`, truck);
        return data;
    } catch (e) {
        console.error("Fail to add hook-up:", truck, e)
    }
}

async function getUploadAudit(date: string, truckId: number, options?: any) {
    try {
        const {data} = await axios.get(`/denmark?truckId=${truckId}&date=${date}`, options);
        return data;
    } catch (e) {
        console.error(`Fail to get audit info for truck id - ${truckId} and date - ${date}`, e)
    }
}

///////////////////////////////////////////////////
//////////////// D H L _ U P L O A D E R  /////////
///////////////////////////////////////////////////

async function getHookUps(username: string) {
    try {
        const {data} = await axios.get(`/dhl/get-trucks?username=${username}`);
        return data;
    } catch (e) {
        console.error(`Fail to get dhl hook-ups for username: ${username}`, e);
    }
}

async function getLCenters() {
    try {
        const {data} = await axios.get(`/dhl/lcenters`);
        return data;
    } catch (e) {
        console.error(`Fail to get logistic centers`, e);
    }
}

async function getTrailers(username: string) {
    try {
        const {data} = await axios.get(`/dhl/get-trailers?username=${username}`);
        return data;
    } catch (e) {
        console.error(`Fail to get dhl hook-ups for username: ${username}`, e);
    }
}


/////////////////////////////////////
//////////////// T R A N V  /////////
/////////////////////////////////////

const getAllVehiclesTranV = async () => await axios.get<TranVVehicle[]>('/tranv').then(res=>res.data)
const addVehicle = async (vehicle: Vehicle) => await axios.post(`/tranv`,vehicle)
const deleteVehicle = async (id:number)=>await axios.delete(`/tranv/${id}`)

/////////////////////////////////////////////
//////////////// L A N G U A G E S  /////////
/////////////////////////////////////////////
async function getBaseTextList() {
    try {
        const {data} = await axios.get<BaseText[]>('/languages/loc-base-texts')
        return data;
    } catch (e) {
        console.error(`Fail to base texts`, e);
    }
}
const getLanguages = async () => axios.get<Language[]>('/languages').then(res => res.data)
const getActiveLanguages = async () => axios.get<Language[]>('/languages/active').then(res => res.data)
const getTranslations = async (languageId:number) => axios.get<TranslationDto[]>(`/languages/translations/${languageId}`).then(res => res.data)

const updateTranslation = async (newTranslation: TranslationUpdateDto) => axios.post('/languages/translations', newTranslation)

/////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////////
const ClientAPI = {
    getCurrencyList,
    getCountriesList,
    auth: {
        login,
        getLoggedUser,
    },
    customers: {
        getAllCustomersNames,
        createCustomer,
        getBasicCustomerInfo,
    },
    drivers: {
        getDrivingTechniqueReport,
        getDriversByCustomer,
    },
    vehicles: {
        getVehicle,
        getVehicleStatus,
        getVehiclePosition,
        getVehicleList,
        getVehiclesByUsername,
        updateProfileInVehicle,
        getVehicleByVin,
        getVehicleByDisplayName
    },
    users: {
        getAllUsers,
        createUser,
        getUser,
        updateUserInfo,
        deleteUser,
        getUsernamesList,
    },
    conversions: {
        getAllReportConversions,
        addReportConversion,
        findAllConversionsByType,
        updateReportConversion,
    },

    diets: {
        getDiets,
        updateDietRate,
        setDietRate,
        deleteDietRate,
    },
    intervals: {
        getAllValidityIntervals,
        updateValidityInterval,
        deleteValidityInterval,
        createValidityInterval,
    },

    profiles: {
        getAllProfiles,
        getVehicleProfileByUsername,
        getAllProfilesByReactQuery: getAllProfiles
    },
    depots: {
        getDepotsByUsername,
        createDepot,
        updateDepot,
        deleteDepot,
        getIdleSettings,
        setIdleSetting
    },
    denmark: {
        getTruckByUsername,
        deleteHookUp,
        addHookUp,
        updateHookUp,
        getUploadAudit
    },
    dhl: {
        getHookUps,
        getLCenters,
        getTrailers,
    },
    tranv: {
        getAllVehiclesTranV,
        addVehicle,
        deleteVehicle
    },
    languages: {
        getBaseTextList,
        getLanguages,
        getActiveLanguages,
        getTranslations,
        updateTranslation,
    }
};
export default ClientAPI
