import {ReactNode, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {GroupLabel} from "./NavigationMenu.styled";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

interface MenuGroupProps {
    groupName: string,
    closeMenu: () => void,
    children?: ReactNode
}

function MenuGroup({groupName,closeMenu, children}:MenuGroupProps) {
    const [showGroup, setShowGroup] = useState(false);

    return (
        <Box>
            <ListItem disablePadding onClick={() => setShowGroup(!showGroup)}>
                <ListItemButton sx={{padding:0}}>
                    <GroupLabel primary={groupName}/>
                    {showGroup ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
            <Collapse in={showGroup} timeout="auto" unmountOnExit>
                <List component="ul" disablePadding onClick={closeMenu}>
                    {children}
                </List>
            </Collapse>
        </Box>
    )
}

export default MenuGroup;